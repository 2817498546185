<template>
  <div class="row mx-0 form-control medium" :class="[color, size, {error: hasErrors > 0}]">
    <div class="col-12 p-0 h-100">
      <div class="row m-0 h-100 align-items-center ">
        <div class="col-auto mt-2 px-1">
          <IconSearch color="green" size="size28" />
        </div>
        <div class="col pe-0 ps-1 ">
          <input
            :type="type"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            v-model="value"
          />
        </div>
        <div class="col-auto mt-2 px-1 cursor">
          <ios-close-icon @click="value = ''"  v-if="value !== ''"/>
        </div>
      </div>
      <div :for="id" class="form-label" v-if="label" v-html="label"></div>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconSearch: defineAsyncComponent(() => import('./icons/IconSearch.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'SearchField',
  props: {
    id: {
      type: String,
      default: Math.random().toString(20).substr(2, 6)
    },
    type: String,
    placeholder: String,
    label: String,
    modelValue: String,
    size: String,
    color: String,
    errors: Array,
    hasErrors: Number,
    autocomplete: String
  },
  data () {
    return {
      showMobile: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input {
    border: none;
    width: 100%;
  }
  input:focus {
    border: none;
    width: 100%;
    outline: none;
  }
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 0;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0;
    font-weight: 500;
    text-align: left;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.outline {
    background-color: #fff;
    color: var(--green-color);
    border: 1px solid;
    border-radius: 2rem;
    border-color: var(--green-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control input:focus {
    color: var(--green-color-light);
  }
  .form-control.outline input:focus {
    color: var(--green-color);
  }
  .form-control.gold input:focus {
    color: var(--gold-color);
  }
  .form-control.white input:focus {
    color: #fff;
  }
  .big {
    height: calc(2.5rem + 4px);
    border: 1px solid var(--green-color-dark);
    border-radius: 2rem;
    font-size: 1rem;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
  @media (min-width: 992px) {
    .form-control {
      background-color: var(--green-color-dark);
      color: var(--green-color-light);
      border-radius: 2rem;
      border: 2px solid var(--green-color-dark);
      font-size: 1rem;
      height: calc(2.5rem + 4px);
      padding: 0 1rem;
      font-weight: 500;
      text-align: left;
    }
    .big {
      height: 4.5rem;
      border-radius: 4rem;
      font-size: 1.8rem;
    }
  }
</style>
